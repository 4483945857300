<!--  -->
<template>
  <div class="About">
    <Navigation />
    <div class="About_title">
      <h3>ABOUT US</h3>
      <h1>关于我们</h1>
    </div>
    <div class="About_content">
      <div class="About_content_chunk">
        <div class="About_content_chunk_title">
          <h2>关于我们</h2>
          <p>ABOUT US</p>
          <div class="wire"></div>
        </div>
        <div class="About_content_chunk_content">
          <p style="text-indent: 2em;">
            <!-- 北京佰达康科技有限公司面向全中国人提供基因检测服务，开展基因生命数据分析业务。并依托基因大数据平台，耗时数年倾情打造了“我和你之间”系列基因大数据应用IP。在今后的时间里，佰达康科技将进行更多尝试，努力让基因文化融入更多人日常生活，用温暖且“接地气”的方式传播文化、焕发IP活力之余，为品牌持续赋能。 -->
            {{ $globalVar.about_desc_about_page }}
          </p>
          <p style="text-indent: 2em;">
            <!-- 北京佰达康科技有限公司面向全中国人提供基因检测服务，开展基因生命数据分析业务。并依托基因大数据平台，耗时数年倾情打造了“我和你之间”系列基因大数据应用IP。在今后的时间里，佰达康科技将进行更多尝试，努力让基因文化融入更多人日常生活，用温暖且“接地气”的方式传播文化、焕发IP活力之余，为品牌持续赋能。 -->
            {{ $globalVar.about_desc_about_page2 }}
          </p>

          
          <img src="../../assets/img/About/About-us.jpg" alt="" />
        </div>
      </div>
      <div class="About_content_chunk">
        <div class="About_content_chunk_title">
          <h2>品牌故事</h2>
          <p class="right">BRAND STORY</p>
          <div class="wire"></div>
        </div>
        <div class="About_content_chunk_brand">
          <div class="About_content_chunk_brand_left">
            <h2 class="About_content_chunk_brand_left_title">我和你之间</h2>
            <div class="About_content_chunk_brand_left_info">
              <p>纷繁复杂的生物圈 ,有一条条无形的碳链把我们相连</p>
              <p>设计师将浪漫的DNA 动人的碳链,</p>
              <p>璀璨夺目的水晶以实物的形式唯美定格</p>
              <p>把两个人的DNA放在一起,</p>
              <p>让载体伴随着生命的能量绽放,</p>
              <p>一切都会是爱的模样</p>
              <p>从细胞到奇点情感终将幻化成无限循环的双螺旋</p>
            </div>
            <div class="About_content_chunk_brand_left_English">
              <p>COMPLEX BIOSPHERE</p>
              <p>THERE ARE INVISIBLE CARBON CHAINS CONNECTING US</p>
              <p>DESIGNERS WILL ROMANTIC DNA AND MOVING CARBON CHAIN</p>
              <p>
                THE DAZZLING CRYSTAL IS AESTHETICALLY FIXED IN
                THEFORMOFPHYSICALOBJECTS
              </p>
              <p>PUT TWO PEOPLE'S DNA TOGETHER</p>
              <p>LET THE CARRIER BLOOM WITH THE ENERGY OF LIFE</p>
              <p>EVERYTHING WILL BE LIKE LOVE, FROM CELL TO SINGULARITY</p>
              <p>
                EMOTION WILL EVENTUALLY TURN INTO A DOUBLE
                HELIXOFINFINITECIRCULATION
              </p>
            </div>
          </div>
          <div class="About_content_chunk_brand_right">
            <img src="../../assets/img/About/brand-r.jpg" alt="" />
          </div>
        </div>

<!-- 底部描述部分 -->
<img src="@/assets/img/About/b_desc_img.png" class="b_desc About_content_chunk_brand_H5">
      </div>
      <div class="About_content_chunk relation">
        <div class="About_content_chunk_title">
          <h2>联系我们</h2>
          <p class="left">CONTACT US</p>
          <div class="wire"></div>
        </div>
        <div class="About_content_chunk_relation">
          <div class="About_content_chunk_relation_content">
            <div class="About_content_chunk_relation_content_left">
              <div class="relation_content_chunk">
                <div class="relation_content_chunk_circle">
                  <img src="../../assets/img/home/icon36.png" alt="" />
                </div>
                <div class="relation_content_chunk_info">
                  <h4>客服电话：</h4>
                  <p>13601124217</p>
                </div>
              </div>
              <div class="relation_content_chunk">
                <div class="relation_content_chunk_circle">
                  <img src="../../assets/img/home/icon33.png" alt="" />
                </div>
                <div class="relation_content_chunk_info">
                  <h4>微信公众号：</h4>
                  <p>EM DNA UOY我和你之间</p>
                </div>
              </div>
              <div class="relation_content_chunk">
                <div class="relation_content_chunk_circle">
                  <img src="../../assets/img/home/icon35.png" alt="" />
                </div>
                <div class="relation_content_chunk_info">
                  <h4>客服时间：</h4>
                  <p>工作日 8:30-17:30</p>
                </div>
              </div>
              <div class="relation_content_chunk">
                <div class="relation_content_chunk_circle">
                  <img src="../../assets/img/home/site.png" alt="" />
                </div>
                <div class="relation_content_chunk_info">
                  <h4>地址：</h4>
                  <p>北京市大兴区荣华南路9号院亦城科技中心2号楼303</p>
                </div>
              </div>
            </div>
            <img
              src="../../assets/img/home/we.png"
              alt="EM DNA UOY我和你之间"
              class="QRCode"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../../components/Navigation.vue";
export default {
  components: { Navigation },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.About_title {
  width: 100%;
  height: 3.5rem;
  background-image: url("../../assets/img/About/banner_bg.jpg");
  padding-top: 1.2rem;
  color: #fff;
  background-repeat: no-repeat;
  box-sizing: border-box;
  text-align: center;
  background-size: 100% 100%;
  h3 {
   font-size: 0.185rem;
    margin-bottom: 0.156rem;
    letter-spacing: 0.08rem;
  }
  h1 {
    font-size: 0.2rem;
     letter-spacing: 0.08rem;
  }
}
.About_content {
  margin-top: 0.525rem;
  .About_content_chunk {
    margin-bottom: 0.94rem;
    .About_content_chunk_title {
      text-align: center;
      position: relative;
      width: 6.4rem;
      margin: 0 auto;
      height: 0.625rem;
      h2 {
        font-size: 32px;
        color: #131a21;
        font-weight: bold;
      }
      p {
        font-size: 0.355rem;
        color: #131a21;
        font-weight: bold;
        opacity: 0.1;
        position: absolute;
        top: 0.05rem;
        letter-spacing: 4px;
        left: 1.9rem;
      }
      .right {
        left: 3rem;
      }
      .left {
        left: 1.1rem;
      }
      .wire {
        width: 0.203rem;
        margin: 0.05rem auto 0;
        height: 0.026rem;
        background-color: #CC9966;
      }
    }
    .About_content_chunk_content {
      width: 65%;
      margin: 0 auto;
      p {
        font-size: 20px;
        font-weight: 400;
        color: #222222;
        line-height: 0.16rem;
        margin-bottom: 26px;
        text-align: justify;
      }
      img {
        width: 100%;
      }
    }
    .About_content_chunk_brand {
      background-color: #131a21;
      height: 4.23rem;
      width: 90%;
      display: flex;
      .About_content_chunk_brand_left {
        flex: 1;
        box-sizing: border-box;
        padding-left: 20.2%;
        background-image: url("../../assets/img/About/brand.jpg");

        .About_content_chunk_brand_left_title {
          margin-top: 0.47rem;
          margin-bottom: 0.32rem;
          font-size: 32px;
          font-weight: bold;
          color: #ffffff;
          padding-left: 0.35rem;
          position: relative;
        }
        .About_content_chunk_brand_left_title::after {
          content: "";
          position: absolute;
          background-color: #fff;
          height: 0.026rem;
          width: 0.34rem;
          left: 0;
          top: 50%;
          margin-top: -0.013rem;
        }
        .About_content_chunk_brand_left_info {
          font-size: 20px;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.16rem;
          opacity: 0.9;
          margin-bottom: 0.16rem;
          letter-spacing: 2px;
        }
        .About_content_chunk_brand_left_English {
          font-size: 18px;
          color: #ffffff;
          line-height: 0.16rem;
          opacity: 0.3;
          letter-spacing: 2px;
        }
      }
      .About_content_chunk_brand_right {
        width: 31%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .About_content_chunk_brand_H5 {
      display: none;
    }
  }
  .relation {
    width: 100%;
    margin-bottom: 0;
    .About_content_chunk_relation {
      background-image: url("../../assets/img/home/ContactUsBackground.jpg");
      padding: 0.26rem 0;
      .About_content_chunk_relation_content {
        margin: 0.47rem auto 0;
        width: 65%;
        display: flex;
        text-align: center;
        .About_content_chunk_relation_content_left {
          display: flex;
          flex-wrap: wrap;
          width: 80%;
          .relation_content_chunk {
            width: 50%;
            height: 0.625rem;
            margin-bottom: 0.42rem;
            display: flex;
            .relation_content_chunk_circle {
              width: 0.594rem;
              height: 0.594rem;
              background-color: #CC9966;
              border-radius: 50%;
              line-height: 0.594rem;
              img {
                width: 0.32rem;
                height: 0.32rem;
              }
            }
            .relation_content_chunk_info {
              flex: 1;
              padding: 0.105rem;
              text-align: left;
              h4 {
                font-size: 24px;
                color: #333333;
                padding: 0.052rem 0;
                font-weight: 900;
              }
              p {
                font-size: 18px;
                color: #333333;
                font-weight: 400;
              }
            }
          }
        }
        .QRCode {
          width: 1.1rem;
          height: 1.1rem;
          margin-top: 0.3rem;
          margin-left: 0.2rem;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
  .About_title {
    width: 100%;
    height: 3.6rem;
    background-image: url("../../assets/img/About/banner_bg.jpg");
    padding-top: 1.43rem;
    color: #fff;
    background-repeat: no-repeat;
    box-sizing: border-box;
    text-align: center;
    background-size: 100% 100%;
    h3 {
      font-size: 0.24rem;
      margin-bottom: 0.156rem;
      letter-spacing: 14px;
    }
    h1 {
      font-size: 0.47rem;
    }
  }
  .About_content {
    margin-top: 0.54rem;
    .About_content_chunk {
      margin-bottom: 0.91rem;
      .About_content_chunk_title {
        text-align: center;
        position: relative;
        width: 6.4rem;
        margin: 0 auto;
        margin-bottom: 0.5rem;
        height: 0.625rem;
        h2 {
          font-size: 0.4rem;
          color: #131a21;
          font-weight: bold;
        }
        p {
          font-size: 0.5rem;
          color: #131a21;
          font-weight: bold;
          opacity: 0.1;
          position: absolute;
          top: 0.05rem;
          letter-spacing: 2px;
          left: 0.5rem;
        }
        .right {
          left: 2.2rem;
        }
        .left {
          left: 0.5rem;
        }
        .wire {
          width: 0.203rem;
          margin: 0.05rem auto 0;
          height: 0.026rem;
          background-color: #cc9966;
        }
      }
      .About_content_chunk_content {
        width: 85%;
        margin: 0 auto;
        margin-top: 0.4rem;
        p {
          font-size: 0.3rem;
          font-weight: 400;
          color: #222222;
          line-height: 0.51rem;
          margin-bottom: 0.26rem;
          text-align: justify;
        }
        img {
          width: 100%;
          height: 2.5rem;
        }
      }
      .About_content_chunk_brand {
        display: none;
      }
      .About_content_chunk_brand_H5 {
        display: block;
        .About_content_chunk_brand_H5_content {
          background-size: 100%;
          padding: 0.94rem 0.69rem;
          color: #fff;
          .About_content_chunk_brand_H5_title {
            font-size: 0.36rem;
            margin-bottom: 0.495rem;
          }
          .About_content_chunk_brand_H5_info {
            font-size: 0.3rem;
            line-height: 0.51rem;
            margin-bottom: 0.5rem;
          }
          .About_content_chunk_brand_H5_English {
            font-weight: 400;
            color: #ffffff;
            line-height: 0.5rem;
            font-size: 0.3rem;
          }
        }
      }
    }
    .relation {
      width: 100%;
      margin-bottom: 0;
      .About_content_chunk_relation {
        background-image: url("../../assets/img/h5/About/contactUs.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 0.26rem 0;
        .About_content_chunk_relation_content {
          margin: 0 auto;
          display: block;
          width: 6.4rem;
          box-sizing: border-box;
          padding: 0.6rem 0;
          .About_content_chunk_relation_content_left {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            .relation_content_chunk {
              width: 50%;
              height: 2.6rem;
              margin-bottom: 1rem;
              display: block;
              .relation_content_chunk_circle {
                width: 1.14rem;
                height: 1.14rem;
                background-color: #cc9966;
                border-radius: 50%;
                line-height: 1.14rem;
                img {
                  width: 0.6rem;
                  height: 0.6rem;
                }
                margin: 0 auto;
              }
              .relation_content_chunk_info {
                flex: 1;
                padding: 0.1rem;
                text-align: left;
                text-align: center;
                h4 {
                  font-size: 0.28rem;
                  color: #333333;
                  padding: 0.05rem 0;
                  font-weight: 900;
                  line-height: 0.6rem;
                }
                p {
                  font-size: 0.28rem;
                  color: #333333;
                  font-weight: 400;
                }
              }
            }
          }
          .QRCode {
            width: 2.5rem;
            height: 2.5rem;
            margin-top: 0.1rem;
            margin-left: 0.2rem;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 751px) and (max-width: 1023px) {
  .About_title {
    h1 {
      font-size: 0.36rem;
    }
  }
  .About_content {
    .About_content_chunk {
      .About_content_chunk_title {
        h2 {
          font-size: 0.3rem;
        }
      }
      .About_content_chunk_content {
        p {
          font-size: 0.205rem;
          line-height: 0.35rem;
        }
      }
      .About_content_chunk_brand_H5 {
        .About_content_chunk_brand_H5_content {
          .About_content_chunk_brand_H5_info {
            font-size: 0.205rem;
            line-height: 0.42rem;
            margin-bottom: 0.78rem;
          }
          .About_content_chunk_brand_H5_English {
            line-height: 0.42rem;
            font-size: 0.205rem;
          }
        }
      }
    }
    .relation {
        margin-bottom: 0;
      .About_content_chunk_relation {
        .About_content_chunk_relation_content {
          .About_content_chunk_relation_content_left {
            .relation_content_chunk {
              .relation_content_chunk_info {
                h4 {
                  line-height: 0.41rem;
                  font-size: 0.205rem;
                }
                p {
                  font-size: 0.205rem;
                  line-height: 0.33rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1680px) {
  .About_content {
    .About_content_chunk {
      margin-bottom: 0.6rem;
      .About_content_chunk_title {
        width: 80%;
        h2 {
          font-size: 32px;
        }
        p {
          font-size: 30px;
          position: absolute;
          top: 0.1rem;
          letter-spacing: 4px;
          left: 2.6rem;
        }
         .left {
        left: 2.5rem;
      }
      }
      .About_content_chunk_content {
        width: 80%;
        p {
          font-size: 18px;
          line-height: 0.2rem;
        }
      }
      .About_content_chunk_brand {
        .About_content_chunk_brand_left {
          padding-left: 9%;
          .About_content_chunk_brand_left_title {
            font-size: 32px;
            margin: 0.3rem 0 0.19rem 0;
          }
          .About_content_chunk_brand_left_info {
            font-size: 18px;
            line-height: 0.2rem;
          }
          .About_content_chunk_brand_left_English {
            font-size: 18px;
            line-height: 0.17rem;
          }
        }
      }
    }
    .relation {
      margin-bottom: 0;
      .About_content_chunk_relation {
        .About_content_chunk_relation_content {
          .relation_content_chunk {
            align-items: center;
            .relation_content_chunk_info {
              h4 {
                font-size: 18px !important;
              }
              p {
                font-size: 14px !important;
              }
            }
          }
        }
      }
    }
  }
}



.b_desc {
  width: 100%;
}
</style>